#sider {
  padding: 2rem;
  background-color: #1e2a3a;
  width: 280px;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#sider > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

#links {
  margin-top: 3rem;
}

#sider ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Quicksand", Arial, sans-serif;
  font-size: 13px;
  font-weight: 500px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#sider #name {
  margin-top: 1.5rem;
}

#sider li {
  padding-bottom: 3px;
  margin: 0.1rem 0;
}

/* inactive states */
#links a {
  color: #fafafa;
  text-decoration: none;
  transition: color 0.5s ease;
}

#links a:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

/* .active states */
#links .scroll-active a {
  color: #54b689;
}

#links .scroll-active a:after {
  width: 100%;
  background: #54b689;
}

#sider #sm-links {
  margin-top: 1rem;
}

#sider #sm-links a {
  background-color: #54b689;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
}

#sider #sm-links img {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  bottom: 2px;
  border-radius: 0;
}

#sider #mail img {
  width: 1rem;
  height: 1rem;
  border-radius: 0;
  margin: 5px;
}

#sider #mail {
  font-size: 13px;
}

#sider-close {
  display: none;
}

@media (max-width: 840px) {
  #sider {
    display: none;
  }

  #sider #sider-close {
    display: block;
    position: fixed;
    padding: 0;
    top: 5px;
    left: 10px;
    cursor: pointer;
  }
}
