/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.css-typing {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.css-typing p {
  color: rgba(255, 255, 255, 0.75);
  font-family: "Anonymous Pro", monospace;

  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 35px;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}
.css-typing p:nth-child(1) {
  margin-top: 3rem;
  width: 350px; /* manually set width */
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 480px; /* manually set width */
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end),
    blink 0.5s step-end infinite alternate;
  animation: type2 2s steps(40, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

@media (max-width: 840px) {
  .css-typing p {
    font-size: 22px;
    width: 60%;
  }
  .css-typing p:nth-child(1) {
    width: 210px;
  }

  .css-typing p:nth-child(2) {
    width: 300px;
  }
}
