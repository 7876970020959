@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
* {
  box-sizing: border-box;
}

html,
body,
#root,
#main-container {
  height: 100%;
  background-color: #111821;
  color: #fafafa;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
}

#main-container {
  display: flex;
  flex-direction: row;
}

#body {
  margin-left: 280px;
  color: #ffffffb3;
}

#body > div {
  /* min-height: 100vh; */
  padding: 3rem;
  border-bottom: 1px solid #ffffff07;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fffffff2;
}

a {
  color: #54b689;
}

.header {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.header:before {
  width: 5px;
  background-color: #54b689;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
}

.card {
  background-color: #151e29;
  padding: 2rem;
  margin-bottom: 2rem;
}

.logo {
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
  border-radius: 50%;
}

.card-title {
  display: flex;
  align-items: center;
}

.card-title h3 {
  margin: 0;
}

.card-sub-title {
  display: flex;
  justify-content: space-between;
}

.card-sub-title p:first-child {
  font-style: italic;
  max-width: 70%;
}

.card-sub-title p {
  margin: 0.5rem 0;
}

.card-content {
  list-style-type: circle;
  padding-left: 1rem;
}

#sider-toggle {
  display: none;
}

@media (max-width: 840px) {
  #body {
    margin-left: 0px;
    margin-top: 1rem;
    width: 100%;
  }

  #body > div {
    padding: 1.5rem;
  }

  #body #sider-toggle {
    display: block;
    position: fixed;
    z-index: 4;
    padding: 0;
    top: 5px;
    left: 10px;
    cursor: pointer;
  }
}


.card-date {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}


/* Google Fonts */

.css-typing {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.css-typing p {
  color: rgba(255, 255, 255, 0.75);
  font-family: "Anonymous Pro", monospace;

  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 35px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.css-typing p:nth-child(1) {
  margin-top: 3rem;
  width: 350px; /* manually set width */
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 480px; /* manually set width */
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end),
    blink 0.5s step-end infinite alternate;
  animation: type2 2s steps(40, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 2px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

@media (max-width: 840px) {
  .css-typing p {
    font-size: 22px;
    width: 60%;
  }
  .css-typing p:nth-child(1) {
    width: 210px;
  }

  .css-typing p:nth-child(2) {
    width: 300px;
  }
}

.carousel-indicators li {
  background-color: #54b689;
}

.carousel {
  margin-top: 1.5rem;
}

.carousel img {
  max-height: 400px;
  object-fit: contain;
}

.sm-logos {
  margin-left: auto;
}

.sm-logos a {
  background-color: #54b689;
  border-radius: 50%;
  padding: 7px;
  margin: 5px;
}

.sm-logo {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
}

.skills-categories {
  display: flex;
}

.skills-categories .card {
  flex: 1 1;
}

#skills h5 {
  margin: 1rem 0;
}

@media (max-width: 544px) {
  .skills-categories {
    flex-direction: column;
  }

  .skills-categories .card {
    align-items: center;
  }

  .skills-categories .card ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


#sider {
  padding: 2rem;
  background-color: #1e2a3a;
  width: 280px;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#sider > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

#links {
  margin-top: 3rem;
}

#sider ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Quicksand", Arial, sans-serif;
  font-size: 13px;
  font-weight: 500px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#sider #name {
  margin-top: 1.5rem;
}

#sider li {
  padding-bottom: 3px;
  margin: 0.1rem 0;
}

/* inactive states */
#links a {
  color: #fafafa;
  text-decoration: none;
  transition: color 0.5s ease;
}

#links a:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

/* .active states */
#links .scroll-active a {
  color: #54b689;
}

#links .scroll-active a:after {
  width: 100%;
  background: #54b689;
}

#sider #sm-links {
  margin-top: 1rem;
}

#sider #sm-links a {
  background-color: #54b689;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
}

#sider #sm-links img {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  bottom: 2px;
  border-radius: 0;
}

#sider #mail img {
  width: 1rem;
  height: 1rem;
  border-radius: 0;
  margin: 5px;
}

#sider #mail {
  font-size: 13px;
}

#sider-close {
  display: none;
}

@media (max-width: 840px) {
  #sider {
    display: none;
  }

  #sider #sider-close {
    display: block;
    position: fixed;
    padding: 0;
    top: 5px;
    left: 10px;
    cursor: pointer;
  }
}

