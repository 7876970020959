.carousel {
  margin-top: 1.5rem;
}

.carousel img {
  max-height: 400px;
  object-fit: contain;
}

.sm-logos {
  margin-left: auto;
}

.sm-logos a {
  background-color: #54b689;
  border-radius: 50%;
  padding: 7px;
  margin: 5px;
}

.sm-logo {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
}
