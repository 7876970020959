#body {
  margin-left: 280px;
  color: #ffffffb3;
}

#body > div {
  /* min-height: 100vh; */
  padding: 3rem;
  border-bottom: 1px solid #ffffff07;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fffffff2;
}

a {
  color: #54b689;
}

.header {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.header:before {
  width: 5px;
  background-color: #54b689;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
}

.card {
  background-color: #151e29;
  padding: 2rem;
  margin-bottom: 2rem;
}

.logo {
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
  border-radius: 50%;
}

.card-title {
  display: flex;
  align-items: center;
}

.card-title h3 {
  margin: 0;
}

.card-sub-title {
  display: flex;
  justify-content: space-between;
}

.card-sub-title p:first-child {
  font-style: italic;
  max-width: 70%;
}

.card-sub-title p {
  margin: 0.5rem 0;
}

.card-content {
  list-style-type: circle;
  padding-left: 1rem;
}

#sider-toggle {
  display: none;
}

@media (max-width: 840px) {
  #body {
    margin-left: 0px;
    margin-top: 1rem;
    width: 100%;
  }

  #body > div {
    padding: 1.5rem;
  }

  #body #sider-toggle {
    display: block;
    position: fixed;
    z-index: 4;
    padding: 0;
    top: 5px;
    left: 10px;
    cursor: pointer;
  }
}
