.skills-categories {
  display: flex;
}

.skills-categories .card {
  flex: 1;
}

#skills h5 {
  margin: 1rem 0;
}

@media (max-width: 544px) {
  .skills-categories {
    flex-direction: column;
  }

  .skills-categories .card {
    align-items: center;
  }

  .skills-categories .card ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
